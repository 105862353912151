import CustomerConsumptionLarge from "../../../../components/CustomerConsumptionLarge/CustomerConsumptionLarge";

function ConsumptionLarge() {
  return (
    <>
      {/* Khách hàng có mức tiêu thụ lớn */}
      <CustomerConsumptionLarge />
    </>
  );
}

export default ConsumptionLarge;

import CustomerClockIndex from "../../../../components/CustomerClockIndex/CustomerClockIndex";

function ClockIndex() {
  return (
    <>
      {/* Báo cáo hóa đơn */}
      <CustomerClockIndex />
    </>
  );
}

export default ClockIndex;

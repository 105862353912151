// data table main
const dataContract = [
  {
    id: "1",
    type_customer: "Cá nhân",
    fullName: "Lê Văn Ba - Nguyễn Thị Xuân",
    code_customer: "NM_HH_so1535",
    address: "Trầm Long - An Hòa",
    phone: "0923204531",
    email: "",
  },
  {
    id: "2",
    type_customer: "Cá nhân",
    fullName: "Lê Văn Ba - Nguyễn Thị Xuân",
    code_customer: "NM_HH_so1535",
    address: "Trầm Long - An Hòa",
    phone: "0923204531",
    email: "",
  },
  {
    id: "3",
    type_customer: "Cá nhân",
    fullName: "Lê Văn Ba - Nguyễn Thị Xuân",
    code_customer: "NM_HH_so1535",
    address: "Trầm Long - An Hòa",
    phone: "0923204531",
    email: "",
  },
  {
    id: "4",
    type_customer: "Cá nhân",
    fullName: "Lê Văn Ba - Nguyễn Thị Xuân",
    code_customer: "NM_HH_so1535",
    address: "Trầm Long - An Hòa",
    phone: "0923204531",
    email: "",
  },
  {
    id: "5",
    type_customer: "Cá nhân",
    fullName: "Lê Văn Ba - Nguyễn Thị Xuân",
    code_customer: "NM_HH_so1535",
    address: "Trầm Long - An Hòa",
    phone: "0923204531",
    email: "",
  },
  {
    id: "6",
    type_customer: "Cá nhân",
    fullName: "Lê Văn Ba - Nguyễn Thị Xuân",
    code_customer: "NM_HH_so1535",
    address: "Trầm Long - An Hòa",
    phone: "0923204531",
    email: "",
  },
  {
    id: "7",
    type_customer: "Cá nhân",
    fullName: "Lê Văn Ba - Nguyễn Thị Xuân",
    code_customer: "NM_HH_so1535",
    address: "Trầm Long - An Hòa",
    phone: "0923204531",
    email: "",
  },
  {
    id: "8",
    type_customer: "Cá nhân",
    fullName: "Lê Văn Ba - Nguyễn Thị Xuân",
    code_customer: "NM_HH_so1535",
    address: "Trầm Long - An Hòa",
    phone: "0923204531",
    email: "",
  },
  {
    id: "9",
    type_customer: "Cá nhân",
    fullName: "Lê Văn Ba - Nguyễn Thị Xuân",
    code_customer: "NM_HH_so1535",
    address: "Trầm Long - An Hòa",
    phone: "0923204531",
    email: "",
  },
  {
    id: "10",
    type_customer: "Cá nhân",
    fullName: "Lê Văn Ba - Nguyễn Thị Xuân",
    code_customer: "NM_HH_so1535",
    address: "Trầm Long - An Hòa",
    phone: "0923204531",
    email: "",
  },
  {
    id: "11",
    type_customer: "Cá nhân",
    fullName: "Lê Văn Ba - Nguyễn Thị Xuân",
    code_customer: "NM_HH_so1535",
    address: "Trầm Long - An Hòa",
    phone: "0923204531",
    email: "",
  },
];

// data table on modal
const dataContractOnModal = [
  {
    id: "1",
    code_clock: "HD1442_1",
    status: "Hủy",
    num_start: "",
    write_order: "54",
    seri: "12D-0121",
    line_reading: "Tuyến cắt nước",
    address_use: "Trầm Lông An Hòa Đoan",
    block_clock: "",
    type_clock: "",
    date_use: "09/01/2023",
    date_install: "",
  },
  {
    id: "2",
    code_clock: "HD1442_1",
    status: "Hủy",
    num_start: "",
    write_order: "54",
    seri: "12D-0121",
    line_reading: "Tuyến cắt nước",
    address_use: "Trầm Lông An Hòa Đoan",
    block_clock: "",
    type_clock: "",
    date_use: "09/01/2023",
    date_install: "",
  },
  {
    id: "3",
    code_clock: "HD1442_1",
    status: "Hủy",
    num_start: "",
    write_order: "54",
    seri: "12D-0121",
    line_reading: "Tuyến cắt nước",
    address_use: "Trầm Lông An Hòa Đoan",
    block_clock: "",
    type_clock: "",
    date_use: "09/01/2023",
    date_install: "",
  },
  {
    id: "4",
    code_clock: "HD1442_1",
    status: "Hủy",
    num_start: "",
    write_order: "54",
    seri: "12D-0121",
    line_reading: "Tuyến cắt nước",
    address_use: "Trầm Lông An Hòa Đoan",
    block_clock: "",
    type_clock: "",
    date_use: "09/01/2023",
    date_install: "",
  },
  {
    id: "5",
    code_clock: "HD1442_1",
    status: "Hủy",
    num_start: "",
    write_order: "54",
    seri: "12D-0121",
    line_reading: "Tuyến cắt nước",
    address_use: "Trầm Lông An Hòa Đoan",
    block_clock: "",
    type_clock: "",
    date_use: "09/01/2023",
    date_install: "",
  },
  {
    id: "6",
    code_clock: "HD1442_1",
    status: "Hủy",
    num_start: "",
    write_order: "54",
    seri: "12D-0121",
    line_reading: "Tuyến cắt nước",
    address_use: "Trầm Lông An Hòa Đoan",
    block_clock: "",
    type_clock: "",
    date_use: "09/01/2023",
    date_install: "",
  },
  {
    id: "7",
    code_clock: "HD1442_1",
    status: "Hủy",
    num_start: "",
    write_order: "54",
    seri: "12D-0121",
    line_reading: "Tuyến cắt nước",
    address_use: "Trầm Lông An Hòa Đoan",
    block_clock: "",
    type_clock: "",
    date_use: "09/01/2023",
    date_install: "",
  },
];

// data table clock on modal
const dataContractClockOnModal = [
  {
    id: "1",
    status: "",
    code: "NM_HH_so2315",
    seri: "",
    date_use: "13/06/2023",
  },
  {
    id: "2",
    status: "",
    code: "NM_HH_so2315",
    seri: "",
    date_use: "13/06/2023",
  },
  {
    id: "3",
    status: "",
    code: "NM_HH_so2315",
    seri: "",
    date_use: "13/06/2023",
  },
  {
    id: "4",
    status: "",
    code: "NM_HH_so2315",
    seri: "",
    date_use: "13/06/2023",
  },
  {
    id: "5",
    status: "",
    code: "NM_HH_so2315",
    seri: "",
    date_use: "13/06/2023",
  },
  {
    id: "6",
    status: "",
    code: "NM_HH_so2315",
    seri: "",
    date_use: "13/06/2023",
  },
  {
    id: "7",
    status: "",
    code: "NM_HH_so2315",
    seri: "",
    date_use: "13/06/2023",
  },
  {
    id: "8",
    status: "",
    code: "NM_HH_so2315",
    seri: "",
    date_use: "13/06/2023",
  },
  {
    id: "9",
    status: "",
    code: "NM_HH_so2315",
    seri: "",
    date_use: "13/06/2023",
  },
  {
    id: "10",
    status: "",
    code: "NM_HH_so2315",
    seri: "",
    date_use: "13/06/2023",
  },
];

// data history use water on modal
const dataHistoryUseWater = [
  {
    id: 1,
    sign: "7/2023",
    lsd: "SH",
    m3_min: "",
    old_index: "132",
    new_index: "135",
    m3_k: "",
    m3_t: "",
    consume: 0,
    total: 120000,
    water_money: 120000,
    vat: 0,
    bvmt: 0,
    cashier: "",
    fall_day: "28/01/2023",
  },
  {
    id: 2,
    sign: "7/2023",
    lsd: "SH",
    m3_min: "",
    old_index: "132",
    new_index: "135",
    m3_k: "",
    m3_t: "",
    consume: 0,
    total: 120000,
    water_money: 120000,
    vat: 0,
    bvmt: 0,
    cashier: "",
    fall_day: "28/01/2023",
  },
  {
    id: 3,
    sign: "7/2023",
    lsd: "SH",
    m3_min: "",
    old_index: "132",
    new_index: "135",
    m3_k: "",
    m3_t: "",
    consume: 0,
    total: 120000,
    water_money: 120000,
    vat: 0,
    bvmt: 0,
    cashier: "",
    fall_day: "28/01/2023",
  },
];

export {
  dataContract,
  dataContractOnModal,
  dataContractClockOnModal,
  dataHistoryUseWater,
};

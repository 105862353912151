import CustomerRevenueAnalysis from "../../../../components/CustomerRevenueAnalysis/CustomerRevenueAnalysis";

function RevenueAnalysis() {
  return (
    <>
      {/* Phân tích doanh thu theo đối tượng giá */}
      <CustomerRevenueAnalysis />
    </>
  );
}

export default RevenueAnalysis;

const endPoints = {
  PUBLIC: "home",
  HOME: "",
  ALL: "*",
  LOGIN: "/",
  REGISTER: 'register',
  FORGET_PASSWORD: 'forget_password',
  OTP_FORGET: 'otp_forget',
  OTP_VERIFY: 'otp_verify',
  CHANGE_PASSWORD: 'change_password'
};
 
export default endPoints;

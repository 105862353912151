import CustomerAboutUseWater from "../../../../components/CustomerAboutUseWater/CustomerAboutUseWater";

function AboutUseWater() {
  return (
    <>
      {/* Khách hàng sử dụng nước trong khoảng */}
      <CustomerAboutUseWater />
    </>
  );
}

export default AboutUseWater;

import TabListMasterClock from "../../../components/TabListMasterClock/TabListMasterClock";

function MasterClock() {
  return (
    <>
      {/* Đổng hồ tổng */}
      <TabListMasterClock />
    </>
  );
}

export default MasterClock;

export const DTTinhGia = [
  {
    label: "Bỏ qua",
    value: 1,
  },
  {
    label: "Theo Hộ",
    value: 2,
  },
  {
    label: "Theo khẩu",
    value: 3,
  },
];

export const CachTinhBVMT = [
  {
    label: "Không Tính ",
    value: 1,
  },
  {
    label: "Phần trăm",
    value: 2,
  },
];

export const KieuTinhGia = [
  {
    label: "Lũy tiến",
    value: 2,
  },
];

export const CachTinhPhiDuyTri = [
  {
    label: "MKhoi",
    value: 1,
  },
  {
    label: "TienHoaDon",
    value: 2,
  },
  {
    label: "TienMKhoi",
    value: 3,
  },
  {
    label: "TienMKhoi_KoMienPhi",
    value: 4,
  },
  {
    label: "MKhoiHoaDon_KoSDNuoc",
    value: 5,
  },
  {
    label: "TienHoaDon_KoSDNuoc",
    value: 6,
  },
];

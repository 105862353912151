import CustomerRevenueMoneyWater from "../../../../components/CustomerRevenueMoneyWater/CustomerRevenueMoneyWater";

function RevenueMoneyWater() {
  return (
    <>
      {/* Sản lượng doanh thu tiền nước */}
      <CustomerRevenueMoneyWater />
    </>
  );
}

export default RevenueMoneyWater;

import CustomerCollectionBillMoneyWater from "../../../../components/CustomerCollectionBillMoneyWater/CustomerCollectionBillMoneyWater";

function CollectionBillMoneyWater() {
  return (
    <>
      {/* Bảng kê thu tiền nước */}
      <CustomerCollectionBillMoneyWater />
    </>
  );
}

export default CollectionBillMoneyWater;

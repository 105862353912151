import CustomerAbnormalWater from "../../../../components/CustomerAbnormalWater/CustomerAbnormalWater";

function AbnormalWater() {
  return (
    <>
      {/* Khách hàng sử dụng nước bất thường */}
      <CustomerAbnormalWater />
    </>
  );
}

export default AbnormalWater;

import CustomerOweMoneyWater from "../../../../components/CustomerOweMoneyWater/CustomerOweMoneyWater";

function OweMoneyWater() {
  return (
    <>
      {/* KH nợ tiền nước */}
      <CustomerOweMoneyWater />
    </>
  );
}

export default OweMoneyWater;

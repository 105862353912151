import CustomerNotReadNumber from "../../../../components/CustomerNotReadNumber/CustomerNotReadNumber";

function NotReadNumber() {
  return (
    <>
      <CustomerNotReadNumber />
    </>
  );
}

export default NotReadNumber;

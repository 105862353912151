import CustomerNotUseWater from "../../../../components/CustomerNotUseWater/CustomerNotUseWater";

function NotUseWater() {
  return (
    <>
      {/* Khách hàng không sử dụng nước */}
      <CustomerNotUseWater />
    </>
  );
}

export default NotUseWater;
